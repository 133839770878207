<template>
  <div v-if="getCartItemsCount == 0">
    <h3 class="faded">Your Basket is empty.</h3>
  </div>

  <div
    v-else
    class="cart-summary-wrap"
  >
    <template v-if="getCartItemsCount">
      <table
        class="cart-summary"
        ref="summaryTable"
      >
        <tbody
          v-if="!hideOrderDetails"
          class="cart-summary-body"
          ref="summaryBody"
        >
          <tr
            v-for="item in cartItems"
            :key="item.id"
          >
            <td class="column">
              <div class="row space-between">
                <div class="row align-flex-start cart-item-name-amount">
                  <span class="cart-item-name"> {{ item.name }}</span>
                  <div class="cart-item-amount align-center">
                    <span
                      v-if="!paymentScreen && !readOnly"
                      @click="modifyAmount(item, -1)"
                      class="cart-item-amount_alter flex-center"
                    >
                      <Icon
                        :path="mdiMinusCircleOutline"
                        :size="18"
                        title="Remove"
                      />
                    </span>
                    <span class="count flex-center">×{{ item.amount }}</span>
                    <span
                      v-if="!paymentScreen && !readOnly && checkComplexity(item)
                        "
                      @click="modifyAmount(item, 1)"
                      class="cart-item-amount_alter flex-center"
                    >
                      <Icon
                        :path="mdiPlusCircleOutline"
                        :size="18"
                        title="Add"
                      />
                    </span>
                  </div>
                </div>
                <div class="cart_price">
                  {{ this.formatMoney(item.itemPriceBase * item.amount) }}
                </div>
              </div>
              <div
                class="cart-item-component-alterations"
                v-if="item.componentAlterations?.length"
              >
                <ul>
                  <li
                    v-for="component of item.componentAlterations"
                    :key="`${item.id}.${component.id}`"
                    class="row space-between"
                  >
                    <span class="cart-alteration-name">
                      {{ renderComponentAlterationLabel(component) }}
                    </span>
                    <span class="extras">
                      {{
                        component.priceToAdd !== 0 && component.levelDelta > 0
                        ? this.formatMoney(
                          component.priceToAdd *
                          component.levelDelta *
                          item.amount
                        )
                        : "Free"
                      }}
                    </span>
                  </li>
                </ul>
              </div>
            </td>
          </tr>
        </tbody>
        <tfoot ref="tfoot">
          <tr v-show="getCartSummary.includeServiceCharge == true">
            <td colspan="2">
              <h4>Subtotal</h4>
            </td>
            <td class="cart_price">
              <h4>{{ formatMoney(getCartSummary.subTotal) }}</h4>
            </td>
          </tr>
          <tr v-show="getCartSummary.includeServiceCharge == true">
            <td
              colspan="2"
              class="extras"
            >
              <span class="flex">
                Service Charge
                <span class="service-charge-info">
                  <Icon
                    :path="mdiInformation"
                    :size="16"
                    :tooltip="getServiceChargeMessage()"
                  />
                </span>
              </span>
            </td>
            <td class="cart_price extras">
              <template v-if="getCartSummary.serviceCharge?.amount">
                {{ formatMoney(getCartSummary.serviceCharge?.amount) }}
              </template>
              <template v-else>
                {{ formatMoney(0) }}
              </template>
            </td>
          </tr>

          <transition name="fade">
            <tr v-if="(getCartSummary.charityDonations?.amount ?? 0 > 0) &&
              donationScreen
              ">
              <td class="extras row">
                Charity Donation
                <span
                  v-if="getCartSummary.charityDonations?.amount && !readOnly && donationScreen"
                  @click="removeDonation()"
                  class="cart-item-amount_alter"
                >
                  <Icon
                    :path="mdiMinusCircleOutline"
                    :size="16"
                  />
                </span>
              </td>
              <td class="extras"></td>
              <td class="cart_price extras row">
                <span class="cart_price__value">
                  <template v-if="getCartSummary.charityDonations?.amount">
                    {{ formatMoney(getCartSummary.charityDonations?.amount) }}
                  </template>
                  <template v-else>
                    {{ formatMoney(0) }}
                  </template>
                </span>
              </td>
            </tr>
          </transition>

          <transition name="fade">
            <tr v-if="(getCartSummary.promotionVouchers?.amount ?? 0 > 0) &&
              (donationScreen || paymentScreen)
              ">
              <td class="extras reduction row">
                Discount
                <span
                  v-if="getCartSummary.promotionVouchers?.amount && !readOnly"
                  @click="removeVoucher(getCartSummary.promotionVouchers)"
                  class="cart-item-amount_alter"
                >
                  <Icon
                    :path="mdiMinusCircleOutline"
                    :size="16"
                    title="Remove"
                  />
                </span>
              </td>
              <td class="extras reduction"></td>
              <td class="cart_price extras row">
                <span class="cart_price__value">
                  <template v-if="getCartSummary.promotionVouchers?.amount">
                    {{ formatMoney(-getCartSummary.promotionVouchers?.amount) }}
                  </template>
                  <template v-else>{{ formatMoney(0) }}</template>
                </span>
              </td>
            </tr>
          </transition>

          <tr>
            <td colspan="2">
              <h3>Total</h3>
            </td>
            <td class="cart_price">
              <h3>{{ formatMoney(getCartSummary.total) }}</h3>
            </td>
          </tr>
        </tfoot>
      </table>
    </template>
  </div>
</template>

<script>
import store from "@/store";
import { mapGetters } from "vuex";
import {
  mdiPlusCircleOutline,
  mdiMinusCircleOutline,
  mdiInformation,
  mdiTag,
  mdiHandHeart,
} from "@mdi/js";

export default {
  data() {
    return {
      serviceId: this.$route.params.serviceId,
      order: null,
      orderId: null,
      mdiPlusCircleOutline,
      mdiMinusCircleOutline,
      mdiInformation,
      mdiTag,
      mdiHandHeart,
    };
  },
  props: {
    readOnly: {
      type: Boolean,
      default: false,
    },
    paymentScreen: {
      type: Boolean,
      default: false,
    },
    donationScreen: {
      type: Boolean,
      default: false,
    },
    hideOrderDetails: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters({
      getCartItems: "cart/getItems",
      getCartItemsCount: "cart/getItemsCount",
      getCartComponentNames: "cart/getComponentNames",
      getCartSummary: "cart/getSummary",
      getRepoItemById: "repoItems/getById",
      getOrderById: "repoOrders/getById",
    }),
    cartItems() {
      let items = this.getCartSummary.items;

      for (const item of items) {
        item.componentAlterations.sort((a, b) => a.levelDelta - b.levelDelta);
      }

      return items;
    },
  },
  emits: ['blockCheckoutContinueButton', 'unblockCheckoutContinueButton'],
  methods: {
    getServiceChargeMessage() {
      const cartSummary = this.getCartSummary;
      const serviceChargePercent = cartSummary?.serviceCharge?.percent;
      const serviceChargeMinimum = cartSummary?.serviceCharge?.minimum;

      if (serviceChargePercent == null || serviceChargeMinimum == null) {
        return "Loading...";
      }

      if (serviceChargePercent <= 0) {
        return `The minimum service charge for each online order is ${this.formatMoney(
          serviceChargeMinimum
        )}.`;
      } else {
        return `This fee is ${serviceChargePercent}% of your basket after promotions or discounts are applied. The minimum service charge for each online order is ${this.formatMoney(
          serviceChargeMinimum
        )}.`;
      }
    },
    modifyAmount(item, delta) {
      store.dispatch("cart/modifyItemAmount", { item, delta });
    },
    async removeDonation() {

      if (this.donationScreen) {
        this.$emit('blockCheckoutContinueButton');
      }
      const response = await store.dispatch("cart/upsertDonation", {
        charityId: 1,
        amount: this.donation ? 0.5 : 0,
      });

      this.donation = false;

      if (this.donationScreen && response) {
        this.$emit('unblockCheckoutContinueButton', response);
      }

    },
    async removeVoucher(voucher) {

      if (this.donationScreen) {

        this.$emit('blockCheckoutContinueButton');
      }
      const response = await store.dispatch("cart/deleteVoucher", voucher.id);

      if (this.donationScreen && response) {
        this.$emit('unblockCheckoutContinueButton', response);
      }

    },
    renderComponentAlterationLabel(component) {
      const { levelDelta, levelAbsolute, name } = component;

      let plusOrMinus = "+";
      let amount = "";

      if (levelDelta < 0 && levelAbsolute > 0) plusOrMinus = "-";
      if (levelDelta < 0 && levelAbsolute <= 0) plusOrMinus = "No";
      if (levelDelta < -1 || levelDelta > 1) {
        let str = levelDelta.toString().substring(0);
        amount = " (×" + str + ")";
      }

      return plusOrMinus === "No"
        ? `${plusOrMinus} ${name}`
        : `${plusOrMinus} ${name} ${amount}`;
    },
    setViewHeight() {
      let vh = window.innerHeight * 0.01;
      this.$refs.summaryBody?.style.setProperty("--vh", `${vh}px`);
    },
    checkComplexity(item) {
      return (
        this.getCartSummary.complexity + item.complexity <= 24 ||
        item.complexity === 0
      );
    },
  },
  async mounted() {
    await store.dispatch("repoServices/loadService", this.serviceId);

    window.addEventListener("resize", () => {
      this.setViewHeight();
    });
  },
  unmounted() {
    window.removeEventListener("resize", this.setViewHeight());
  },
};
</script>

<style lang="scss" scoped>
table.cart-summary {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 1rem;
  overflow: auto;
  position: relative;
  line-height: 1.2em;

  td {
    vertical-align: top;
    padding: 0.5rem 0;
  }

  tbody {
    overflow: auto;
    display: table-caption !important;
    max-height: calc(100vh - 400px);

    .cart-active & {
      @media screen and (max-width: 960px) {
        height: 100vh;
        /* Fallback for browsers that do not support Custom Properties */
        height: calc(var(--vh, 1vh) * 100 - 300px);
        min-height: 250px;
      }

      @media screen and (max-width: 480px) {
        height: 100vh;
        /* Fallback for browsers that do not support Custom Properties */
        height: calc(var(--vh, 1vh) * 100 - 360px);
        min-height: 150px;
        max-height: 50vh;
      }
    }

    .unable-checkout & {
      @media screen and (max-width: 960px) {
        height: 100px;
        min-height: auto;
      }
    }

    tr {
      display: inline-table;
      width: 100%;
    }
  }

  h4 {
    margin-bottom: 0;
  }

  tbody tr {
    border-bottom: 1px solid $col_grey_green;
  }

  .cart-item-amount {
    .count {
      font-size: 1em;
    }
  }

  tfoot {
    tr td {
      padding: 0.25rem 0;
    }

    tr:first-child td {
      padding: 1rem 0 0.2rem 0;
    }
  }

  .material-icons-outlined {
    font-size: 0.875rem;
  }

  .mobileLayout.cart-active & {
    @media screen and (max-width: 960px) {

      tfoot,
      tbody {
        display: flex !important;
        flex-direction: column;
        width: 100%;

        tr {
          display: flex !important;
          width: 98%;
          flex-direction: row;
          justify-content: space-between;
          font-size: 0.875rem;
          align-items: flex-start;
        }
      }

      tfoot {
        tr {
          width: 100%;
        }
      }
    }
  }

  .cart_price {
    text-align: right;
    width: 100px;

    &__value {
      justify-content: flex-end;
      display: flex;
      width: 100%;
    }
  }

  .extras {
    color: $col_faded-darker;
    font-size: 0.875rem;

    &.row {
      gap: 0.25rem;
    }

    .service-charge-info {
      margin-left: 5px;
      position: relative;
      height: 18px;
    }
  }

  .mobileLayout & {
    z-index: 12;

    tbody {
      td.cart-item-amount {
        width: 50px;
      }
    }
  }

  .mobileLayout .charity-promo-wrapper & {
    width: 250px;
  }

  .mobileLayout.cart-active & {
    display: block;

    tbody {
      tr {
        td.cart-item-name {
          display: revert;
          width: 50%;
          padding-bottom: 0.5rem;
          vertical-align: top;

          @media screen and (min-width: 425px) {
            width: 60%;
          }
        }

        td.cart-item-amount {
          display: flex;
        }
      }
    }

    tfoot {
      tr {
        td:first-child {
          width: 200px;
        }
      }
    }
  }
}

.empty-cart-message {
  padding: 5rem 0;
  text-align: center;
}

.cart-item-name {
  width: 50%;
  min-width: 200px;
  max-width: 400px;
  font-weight: 400;

  @media screen and (max-width: 1279px) and (min-width: 1024px) {
    width: 130px;
    min-width: auto;
    max-width: none;
  }

  @media screen and (max-width: 1023px) and (min-width: 961px) {
    width: 100px;
    min-width: auto;
    max-width: none;
  }

  @media screen and (max-width: 425px) {
    min-width: auto;
    max-width: none;
  }
}

.cart-alteration-name {
  width: 50%;
  min-width: 200px;
  max-width: 400px;
  font-weight: 400;

  @media screen and (max-width: 1023px) and (min-width: 961px) {
    width: 200px;
    min-width: auto;
    max-width: none;
  }

  @media screen and (max-width: 425px) {
    width: 150px !important;
    min-width: auto;
    max-width: none;
  }

  @media screen and (max-width: 374px) {
    width: 120px !important;
  }
}

td.cart-item-amount {
  position: relative;
  justify-content: end;

  @media screen and (max-width: 960px) {
    top: 0;
  }
}

.cart-item-amount_alter {
  cursor: pointer;
  color: $col_beta-darker;
  margin: 0 0.25rem;
  height: 16px;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.cart-item-component-alterations {
  padding-top: 0 !important;

  &>ul {
    width: 100%;

    li {
      padding: 0.15rem 0;

      &:not(:last-child) {
        border-bottom: 1px dashed #ddd;
      }

      .extras {
        font-size: 0.8rem;
      }
    }
  }
}

.cart-item-name-amount {
  width: 100%;
}

@media screen and (max-width: $mobile_max_width) {
  table.cart-summary {
    .cart-item-name {
      width: calc(100vw - 150px);
      min-width: 100px;
      max-width: 150px;
      font-size: 0.875rem;
    }

    .count {
      width: 30px;
      font-size: 0.8rem;
    }

    .cart-item-amount_alter {
      margin: 0;
      width: 30px;
    }

    .cart_price {
      width: 70px;
    }
  }
}

@media screen and (max-width: $small_mobile_width) {
  table.cart-summary {
    .cart-item-name {
      max-width: 100px;
    }
  }
}

table.cart-summary {
  tbody.cart-summary-body {
    margin-right: -1rem;
    padding-right: 1rem;
  }

  .cart_price {
    width: 70px;
  }
}

.row__icon {
  margin-right: 5px;
}
</style>
